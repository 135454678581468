<template>
    <ConfirmDialog ref="confirmDialog"/>
    <SmartTable :items="items" :infinity="true" :columns="columns" class="schedule-table" :table-name="tableName">
        <template #name="{ item }">
            <div class="schedule-table__name-wrapper">
                <CIcon v-if="item.is_main" name="cilCrownLabel"/>
                <div class="schedule-table__name">
                    <span class="text-overflow-ellipsis" :title="item.name">
                        {{ item.name }}
                    </span>
                </div>
            </div>
        </template>

        <template #file_name="{ item }">
            <div class="schedule-table__file">
                <span class="text-overflow-ellipsis" :title="item.file_name + 'aaaa'"
                      @click="toggleDocumentPreviewer(item.template.id, item.type, item.template.upload.extension)">
                    {{ item.file_name }}
                </span>
            </div>
        </template>

        <template #task_action="{ item }">
            {{ displayTaskAction(item) }}
        </template>

        <template #start_date="{ item }">
            <input class="schedule-table__date" :value="item.is_indefinite ? 'N/A' : formatDate(item.start_date)"
                   type="text" readonly/>
        </template>

        <template #due_date="{ item }">
            <input class="schedule-table__date" :value="item.is_indefinite ? 'N/A' : formatDate(item.due_date)"
                   type="text" readonly/>
        </template>
    </SmartTable>
    <DocumentPreviewer :visible="documentPreviewer.visible" type="templates" :type-id="documentPreviewer.typeId"
                       :previewer="documentPreviewer.previewer" :extension="documentPreviewer.extension"
                       @closed="toggleDocumentPreviewer(null, null)"/>
</template>

<script>
import SmartTable from '@/components/SmartTable/index.vue';
import ConfirmDialog from '@/components/Modals/ConfirmDialog.vue';
import DocumentPreviewer from '@/components/Documents/DocumentPreviewer.vue';
import moment from 'moment/moment';
import {CONTRACT_TEMPLATE_TYPE} from "@/domain/Entities/Template/templateTypes";

export default {
    name: 'ScheduleDatesTable',
    components: {ConfirmDialog, SmartTable, DocumentPreviewer},
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        tableName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            columns: [
                {key: 'name', label: 'Name', _style: '', sorter: false},
                {key: 'due_date', label: 'Due Date', _style: '', sorter: false},
                {key: 'start_date', label: 'Assigned Date', _style: '', sorter: false},
                {key: 'file_name', label: 'Document', _style: '', sorter: false},
                {key: 'required_action', label: 'Action', _style: '', sorter: false},
            ],
            dates: {
                start_date: '',
                due_date: '',
            },
            documentPreviewer: {
                visible: false,
                previewer: null,
                extension: null,
                typeId: null,
            },
        };
    },
    methods: {
        formatDate(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        displayTaskAction(task) {
            if (task.task_action === 'Acknowledgement of Receipt Required') return 'Ack. of Receipt';

            return task.task_action;
        },
        toggleDocumentPreviewer(taskId, templateType, templateExtension) {
            this.documentPreviewer.typeId = taskId;
            this.documentPreviewer.extension = templateExtension;
            this.documentPreviewer.previewer = templateType === CONTRACT_TEMPLATE_TYPE ? 'third-party' : 'default';
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
        },
    },
};
</script>

<style lang="scss">
.schedule-table {
    & table {
        table-layout: fixed;
    }

    & .c-input-date {
        margin-bottom: 0 !important;
    }

    & .form-control[readonly] {
        background: white !important;
    }

    &__date {
        min-height: 38px;
        width: 100%;
        background: white;
        border: 1px solid var(--cui-input-border-color, #b1b7c1);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
        outline: none;
    }

    &__name {
        width: calc(100% - 25px);

        &-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            gap: 10px;

            & svg {
                width: 16px !important;
                height: 16px !important;
            }
        }
    }

    &__file {
        color: #0068ad;
        font-size: 14px;
        text-decoration: underline;
        width: 100%;
        cursor: pointer;
    }
}
</style>
